import React from "react";
import { createRoot } from 'react-dom/client';
import Preloader from "./components/Preloader/Preloader";
import Timer from "./components/Timer/Timer";
import {Container} from "@mui/material";
import { Helmet } from "react-helmet";
import { MouseParallaxContainer, MouseParallaxChild } from "react-parallax-mouse";
import logo from "./images/sflv-logo.png";

import "./main.scss";

function App() {
        return (
            <div className="App">
                <Helmet>
                    <title>SFLV: Gaidīsim Tevi mūsu mājaslapā vēlāk, jo šobrīd izstrādājam jaunu un unikālu produktu</title>
                    <meta name="keywords" content="SFLV: Gaidīsim Tevi mūsu mājaslapā vēlāk, jo šobrīd izstrādājam jaunu un unikālu produktu" />
                    <meta
                        name="description"
                        content="SFLV: Gaidīsim Tevi mūsu mājaslapā vēlāk, jo šobrīd izstrādājam jaunu un unikālu produktu "
                    />
                </Helmet>

                <MouseParallaxContainer maxWidth="xl" className="container" globalFactorX={0.1} globalFactorY={0.1}>
                    <MouseParallaxChild factorX={0.3} factorY={0.5}>
                        <Container maxWidth="xl" >
                            <div className="divLogo">
                                <img src={logo} className="logo" alt="sflv.lv"/>
                            </div>
                            <div className="divTimer">
                                <Timer/>
                            </div>
                            <h1 className="Animation">
                                Gaidīsim Tevi mūsu mājaslapā vēlāk, jo šobrīd <br /> izstrādājam jaunu un unikālu produktu
                            </h1>
                        </Container>
                    </MouseParallaxChild>

                    <MouseParallaxChild factorX={0.7} factorY={0.8}>
                        <img src="images/bg-riga.jpg" alt="" />
                    </MouseParallaxChild>
                </MouseParallaxContainer>
                <Preloader />
            </div>
        );
}
const root = createRoot(document.getElementById('root'));
root.render(<App />);